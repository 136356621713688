import React from "react";

import { NiceHr } from "../Components/NiceHr";
import { Index } from "./Index";
import { Not } from "./Not";
import { Age } from "./Age";
import { Babys } from "./Ages/Babys";
import { Children } from "./Ages/Children";
import { Adults } from "./Ages/Adults";
import { Sick } from "./Ages/Adults/Sick";
import { RiskGroup } from "./Ages/Adults/RiskGroup";
import { Pregnant } from "./Ages/Adults/Pregnant";
import { Seniors } from "./Ages/Seniors";
import { Search } from "./Search";
import { Final } from "./Final";

export const Debug = () => {
  return (
    <>
      <h1 class="text-4xl py-10">ALL THE VIEWS - DEBUG</h1>

      <NiceHr text="index" />
      <Index />

      <NiceHr text="age" />
      <Age />

      <NiceHr text="babys" />
      <Babys />

      <NiceHr text="children" />
      <Children />

      <NiceHr text="adults" />
      <Adults />

      <NiceHr text="sick" />
      <Sick />

      <NiceHr text="riskgroup" />
      <RiskGroup />

      <NiceHr text="pregnant" />
      <Pregnant />

      <NiceHr text="seniors" />
      <Seniors />

      <NiceHr text="not" />
      <Not />

      <NiceHr text="search" />
      <Search />

      <NiceHr text="final" />
      <Final />
    </>
  );
};
