import React from "react";
import { useState } from "react";
import { Button } from "../Components/Button";
import { municipalitiesWithParent } from "../Assets/Places";

export const Search = () => {
  const [inputValue, setInputValue] = useState("");
  const [place, setPlace] = useState(null);

  const navigateTo = (place) => {
    window.location.href = place?.link;
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (!value) {
      setPlace(null);
      return;
    }

    const foundPlaces = municipalitiesWithParent.filter((place) => {
      return place.name.toLowerCase().startsWith(value.toLowerCase());
    });

    if (foundPlaces.length === 1) {
      setPlace(foundPlaces[0]);
    } else {
      setPlace(null);
    }
  };

  return (
    <>
      <h1>Hae hyvinvointialueesi rokotuspisteen yhteystiedot</h1>

      <input
        className="px-4 py-4 bg-sky-100 w-80"
        type="text"
        placeholder="ASUINPAIKKAKUNTASI"
        autoFocus
        value={inputValue}
        onChange={handleInputChange}
      />

      <span className={`text-base ${!place ? "invisible" : ""}`}>
        {place?.name === "Helsinki"
          ? "Helsinki"
          : `${place?.name} kuuluu ${place?.parent}eseen`}
      </span>
      <Button
        onClick={() => navigateTo(place)}
        className={!place ? "invisible" : ""}
      >
        ROKOTUSPISTEEN TIEDOT &gt;
      </Button>
    </>
  );
};
