import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "../Components/ButtonGroup";
import { Button } from "../Components/Button";

export const Age = () => {
  const navigate = useNavigate();

  return (
    <>
      <h1 class="text-4xl pb-10">IKÄSI</h1>
      <ButtonGroup>
        <Button width={true} onClick={() => navigate("babys")}>
          ALLE 6 KK
        </Button>
        <Button width={true} onClick={() => navigate("children")}>
          6 KK - 6 V
        </Button>
        <Button width={true} onClick={() => navigate("adults/sick")}>
          7 - 64 V
        </Button>
        <Button width={true} onClick={() => navigate("seniors")}>
          65 V +
        </Button>
      </ButtonGroup>
    </>
  );
};
