// import logo from './logo.svg';
import './App.css';
import { Container } from './Components/Container';
import { Index } from './Pages/Index';
import { Age } from './Pages/Age';
import { Babys } from './Pages/Ages/Babys';
import { Children } from './Pages/Ages/Children';
import { Adults } from './Pages/Ages/Adults';
import { Sick } from './Pages/Ages/Adults/Sick';
import { RiskGroup } from './Pages/Ages/Adults/RiskGroup';
import { Pregnant } from './Pages/Ages/Adults/Pregnant';
import { PregnantOk } from './Pages/Ages/Adults/PregnantOk';
import { Seniors } from './Pages/Ages/Seniors';
import { Not } from './Pages/Not';
import { Search } from './Pages/Search';
import { Final } from './Pages/Final';
import { Debug } from './Pages/Debug';
import { Info } from './Pages/Info';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {

  return (
    <Router>
      <Container>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route path="/age" element={<Age />} />
          <Route path="/age/babys" element={<Babys />} />
          <Route path="/age/children" element={<Children />} />
          <Route path="/age/adults" element={<Adults />} />
          <Route path="/age/adults/pregnantok" element={<PregnantOk />} />
          <Route path="/age/adults/sick" element={<Sick />} />
          <Route path="/age/adults/riskgroup" element={<RiskGroup />} />
          <Route path="/age/adults/pregnant" element={<Pregnant />} />
          <Route path="/age/seniors" element={<Seniors />} />
          <Route path="/not" element={<Not />} />
          <Route path="/search" element={<Search />} />
          <Route path="/final" element={<Final />} />
          <Route path="/info" element={<Info />} />
          <Route path="/debug" element={<Debug />} />

          <Route path="*" element={<h1>Sivua ei löytynyt</h1>} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
