import React from "react";

export const NiceHr = ({ text }) => {
  return (
    <div class="inline-flex items-center justify-center w-full">
      <hr class="w-64 h-1 my-8 bg-gray-200 border-0 rounded " />
      <div class="absolute px-4 -translate-x-1/2 bg-white left-1/2 text-gray-500">
        {text}
      </div>
    </div>
  );
};
