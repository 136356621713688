import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "../../../Components/ButtonGroup";
import { Button } from "../../../Components/Button";

export const RiskGroup = () => {
  const navigate = useNavigate();

  return (
    <>
      <span>
        Kuuluuko lähipiiriisi joku, joka on{" "}
        <a href="https://thl.fi/aiheet/infektiotaudit-ja-rokotukset/rokotteet-a-o/influenssarokote/kenelle-ilmainen-influenssarokote-/alttiiden-henkiloiden-lahipiirin-influenssarokotukset">
          erityisen altis vakavalle influenssalle
        </a>
        ?
      </span>
      <ButtonGroup>
        <Button onClick={() => navigate("/age/adults")} width={true}>
          KYLLÄ
        </Button>
        <Button onClick={() => navigate("/age/adults/pregnant")} width={true}>
          EI
        </Button>
      </ButtonGroup>
    </>
  );
};
