import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "../../../Components/ButtonGroup";
import { Button } from "../../../Components/Button";

export const Pregnant = () => {
  const navigate = useNavigate();

  return (
    <>
      <span>OLETKO RASKAANA</span>
      <ButtonGroup>
        <Button onClick={() => navigate("/age/adults/pregnantok")} width={true}>
          KYLLÄ
        </Button>
        <Button onClick={() => navigate("/not")} width={true}>
          EN
        </Button>
      </ButtonGroup>
    </>
  );
};
