import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Components/Button";

export const Not = () => {
  const navigate = useNavigate();
  return (
    <>
      <span>
        Näyttää ettet ole oikeutettu maksuttomaan influenssarokotukseen.
        Halutessasi voit hankkia rokotteen omakustanteisesti.
      </span>

      <Button onClick={() => navigate("/")}>Takaisin alkuun</Button>
    </>
  );
};
