import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Components/Button";

export const Babys = () => {
  const navigate = useNavigate();

  return (
    <>
      <p className="text-center">
        Alle 6 kuukauden ikäisiä ei voida rokottaa influenssarokotteella, mutta
        heitä voidaan epäsuorasti suojata lähipiirin rokottamisella. Lähipiiriin
        kuuluville henkilöille suositellaan influenssarokotetta ja he ovat oikeutettuja maksuttomaan rokotukseen.
      </p>

      <a
        href="https://thl.fi/aiheet/infektiotaudit-ja-rokotukset/rokotteet-a-o/influenssarokote/vasta-aiheet-ja-varotoimet-influenssarokotuksissa"
        target="_blank"
        rel="noreferrer"
      >
        VARMISTA AINA, ONKO ROKOTTAMISELLE VASTA-AIHEITA
      </a>

      <Button onClick={() => navigate("/search")}>SEURAAVA &gt;</Button>
    </>
  );
};
