import React from "react";
import { Button } from "../Components/Button";

export const Final = () => {
  const handleExternalNavigation = () => {
    window.location.href = "https://thl.fi";
  };

  return (
    <>
      <img src="final.png" alt="influenssa" width="200px" />
      <Button onClick={() => handleExternalNavigation()}>
        ALUEESI ROKOTUSPALVELUT &gt;
      </Button>
    </>
  );
};
