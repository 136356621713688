import React from "react";

export const Button = ({ children, onClick, width, className }) => {
  return (
    <button
      className={`bg-white hover:bg-sky-100 border border-stone-400 px-4 py-3 ${
        width ? "min-w-80" : ""
      } ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
