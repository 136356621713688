import React from "react";
import { Button } from "../Components/Button";

export const Info = () => {
  return (
    <>
      <p className="text-center">
        Kahden kehittämismyönteisen terveydenhoitajan ideoima ja suunnittelema.
        Yhteistyöpyynnöt, kehittämisideat sekä muu palaute sähköpostilla.
        <div className=""></div>
      </p>
      <p className="text-center">
        <a href="mailto:rokotearvio@gmail.com">rokotearvio@gmail.com</a>
      </p>
      <Button onClick={() => window.history.back()}>Takaisin</Button>
    </>
  );
};
